import React, { useState, useEffect } from 'react';
import { trackEvent, ANALYTICS_EVENTS } from '../analytics/config';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setShowBanner(false);
    trackEvent(ANALYTICS_EVENTS.BUTTON_CLICK, {
      button_name: 'cookie_consent_accept',
      page: 'cookie_banner'
    });
    // Enable Google Analytics tracking here if you've disabled it by default
  };

  const handleDecline = () => {
    localStorage.setItem('cookie-consent', 'declined');
    setShowBanner(false);
    trackEvent(ANALYTICS_EVENTS.BUTTON_CLICK, {
      button_name: 'cookie_consent_decline',
      page: 'cookie_banner'
    });
    // Disable Google Analytics tracking here
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-4 z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-600">
          We use cookies to analyze site usage and improve our services. 
          See our{' '}
          <a href="/privacy" className="text-purple-600 hover:text-purple-800">
            Privacy Policy
          </a>
          {' '}for details.
        </div>
        <div className="flex gap-4">
          <button
            onClick={handleDecline}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          >
            Decline
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 text-sm bg-purple-600 text-white rounded-md hover:bg-purple-700"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 