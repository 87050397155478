import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import AboutPage from './components/AboutPage';
import PrivacyPage from './components/PrivacyPage';
import Footer from './components/Footer';
import { initializeLandingAnalytics, trackPageView, ANALYTICS_EVENTS, trackEvent } from './analytics/config';
import CookieConsent from './components/CookieConsent';

function App() {
  useEffect(() => {
    // Initialize analytics
    initializeLandingAnalytics('YOUR_MEASUREMENT_ID'); // Replace with your actual GA measurement ID
  }, []);

  // Track page views when route changes
  useEffect(() => {
    const handleRouteChange = () => {
      trackPageView(window.location.pathname);
    };

    // Track initial page view
    handleRouteChange();

    // Add listener for route changes
    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>
        </div>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;