import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Heart } from 'lucide-react';
import { trackEvent, ANALYTICS_EVENTS } from '../analytics/config';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleLinkClick = (linkName) => {
    trackEvent(ANALYTICS_EVENTS.BUTTON_CLICK, {
      button_name: linkName,
      section: 'footer'
    });
  };

  const handleContactClick = () => {
    trackEvent(ANALYTICS_EVENTS.BUTTON_CLICK, {
      button_name: 'contact_email',
      section: 'footer'
    });
  };

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          {/* Brand Section */}
          <div className="space-y-4 xl:col-span-1">
            <Link 
              to="/" 
              className="text-purple-600 font-semibold text-lg hover:text-purple-700"
              onClick={() => handleLinkClick('footer_logo')}
            >
              HumanBetter
            </Link>
            <p className="text-gray-500 text-sm">
              Making meaningful connections easier, one moment at a time.
            </p>
          </div>
          
          {/* Links Section */}
          <div className="mt-8 xl:mt-0 xl:col-span-2">
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-2">
                  <li>
                    <Link 
                      to="/about" 
                      className="text-gray-500 hover:text-purple-600"
                      onClick={() => handleLinkClick('about_page')}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/privacy" 
                      className="text-gray-500 hover:text-purple-600"
                      onClick={() => handleLinkClick('privacy_page')}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Connect
                </h3>
                <ul className="mt-4 space-y-2">
                  <li>
                    <a 
                      href="mailto:contact@humanbetter.com" 
                      className="text-gray-500 hover:text-purple-600"
                      onClick={handleContactClick}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        {/* Bottom Section */}
        <div className="mt-8 border-t border-gray-200 pt-8">
          <p className="text-sm text-gray-500 text-center flex items-center justify-center">
            Made with <Heart size={16} className="text-red-500 mx-1" /> by HumanBetter | © {currentYear} HumanBetter. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;