import React, { useEffect } from 'react';
import { Shield, Mail, MapPin, Lock, Eye, RefreshCw, BarChart } from 'lucide-react';
import { trackEvent, ANALYTICS_EVENTS } from '../analytics/config';

const PrivacyPage = () => {
  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.LANDING_PAGE_VIEW, {
      section: 'privacy',
      page_name: 'Privacy Policy'
    });
  }, []);

  const handleSectionView = (sectionName) => {
    trackEvent(ANALYTICS_EVENTS.FEATURE_SECTION_VIEW, {
      section_name: sectionName,
      page: 'privacy'
    });
  };

  const handleContactClick = () => {
    trackEvent(ANALYTICS_EVENTS.BUTTON_CLICK, {
      button_name: 'privacy_contact',
      page: 'privacy'
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            handleSectionView(entry.target.dataset.section);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('[data-section]').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-xl text-gray-600">
            Simple, transparent, and respectful of your privacy.
          </p>
        </div>

        <div className="space-y-12">
          {/* Introduction */}
          <section data-section="introduction" className="prose prose-purple max-w-none">
            <p className="text-gray-600 leading-relaxed">
              At HumanBetter, we take your privacy seriously. We believe in being completely 
              transparent about how we collect and use your data, while ensuring it remains 
              secure and private.
            </p>
          </section>

          {/* Data Collection Section */}
          <section data-section="data-collection">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
              <Shield className="text-purple-600 mr-3" />
              Data We Collect
            </h2>
            <div className="space-y-6">
              <div className="flex items-start">
                <Mail className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Email Address</h3>
                  <p className="text-gray-600">
                    Currently, we only collect your email address for waitlist registration 
                    and future communication about our service launch.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <BarChart className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Analytics and Tracking</h3>
                  <p className="text-gray-600">
                    We use Google Analytics to understand how visitors interact with our website. 
                    This includes:
                  </p>
                  <ul className="list-disc ml-6 mt-2 text-gray-600">
                    <li>Pages visited and time spent on our website</li>
                    <li>Your device information and browser type</li>
                    <li>General location data (city/country level)</li>
                    <li>IP addresses (anonymized)</li>
                    <li>Interaction with site features</li>
                  </ul>
                  <div className="mt-4 p-4 bg-purple-100 rounded-lg">
                    <p className="text-sm text-gray-700">
                      <strong>Your Consent:</strong> By using our website, you consent to the processing 
                      of your data by Google Analytics. You can withdraw your consent at any time by 
                      using the{' '}
                      <a 
                        href="https://tools.google.com/dlpage/gaoptout"
                        className="text-purple-600 hover:text-purple-800 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleSectionView('analytics_opt_out')}
                      >
                        Google Analytics Opt-out Browser Add-on
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <MapPin className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Future Data Collection</h3>
                  <p className="text-gray-600">
                    In the future, we plan to collect basic information such as your name 
                    and zip code to provide personalized services. We will update this 
                    privacy policy accordingly when these changes take effect.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Data Usage Section */}
          <section data-section="data-usage">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
              <Lock className="text-purple-600 mr-3" />
              How We Use Your Data
            </h2>
            <div className="space-y-6">
              <div className="flex items-start">
                <Eye className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">No Data Reselling</h3>
                  <p className="text-gray-600">
                    We do not sell, rent, or share your personal information with third parties. 
                    Your data is used exclusively to provide and improve our services.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <RefreshCw className="text-purple-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Service Improvement</h3>
                  <p className="text-gray-600">
                    We may use aggregated, anonymized data to improve our services and user 
                    experience. This data cannot be used to identify individual users.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Cookie Policy Section */}
          <section data-section="cookie-policy">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
              <Lock className="text-purple-600 mr-3" />
              Cookie Policy
            </h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-600 mb-4">
                We use cookies and similar tracking technologies to:
              </p>
              <ul className="list-disc ml-6 text-gray-600 mb-4">
                <li>Understand how you use our website</li>
                <li>Remember your preferences</li>
                <li>Improve your experience</li>
                <li>Analyze our website performance</li>
              </ul>
              <p className="text-gray-600">
                Essential cookies are always active as they are necessary for the website to function. 
                Analytics cookies are only set with your consent. You can change your cookie 
                preferences at any time by clearing your browser cookies or using the opt-out 
                tools provided above.
              </p>
            </div>
          </section>

          {/* Contact Section */}
          <section data-section="contact" className="bg-purple-50 p-6 rounded-lg">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Questions About Privacy?</h2>
            <p className="text-gray-600 mb-4">
              If you have any questions or concerns about our privacy practices, please don't 
              hesitate to contact us.
            </p>
            <a 
              href="mailto:privacy@humanbetter.com" 
              className="text-purple-600 hover:text-purple-700 font-medium"
              onClick={handleContactClick}
            >
              privacy@humanbetter.com
            </a>
          </section>

          {/* Last Updated */}
          <p className="text-sm text-gray-500 text-center">
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;